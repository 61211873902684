import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "shop"
    }}>{`Shop`}</h1>
    <p>{`The shop is the main way for players to increase their power over the course of a game.
To display the shop, press Alt. You must be on a regen pad to buy items.`}</p>
    <p><img alt="The Shop" src={require("../public/shop.png")} /></p>
    <p style={{
      "textAlign": "center",
      "fontSize": "0.8em",
      "marginTop": "-25px"
    }}><em>The Shop</em></p>
    <p>{`Your stats are displayed at the right on the screen. If you select an item, the changes induced by this item are displayed next to your characteristics.`}</p>
    <p><img alt="Characteristics Upgrade" src={require("../public/characteristics-upgrade.png")} /></p>
    <h2 {...{
      "id": "items"
    }}>{`Items`}</h2>
    <p>{`All items cost `}<a parentName="p" {...{
        "href": "/players#coppers"
      }}>{`Coppers`}</a>{`. Items you have purchased have a blue outline. Items you have equipped have a green dot on their picture.`}</p>
    <p><img alt="Equipped item" src={require("../public/greendot-item.png")} /></p>
    <p>{`You can always buy weapons and armors/exoskeletons but you can buy only items on the current unlocked line (a green arrow indicates the current choice).  `}</p>
    <p><img alt="Items line" src={require("../public/itemsline.png")} />{`  `}</p>
    <p>{`When you buy an item, the next line is unlocked. `}<strong parentName="p">{`You can only buy one item per line, choose wisely!`}</strong></p>
    <h3 {...{
      "id": "exoskeletons"
    }}>{`Exoskeletons`}</h3>
    <p>{`A player equipped with an Exoskeleton is invincible if at least two of his opponents have the same type of weapon (Weapon types are Rocket, Laser, Nucleus or Arrow).`}</p>
    <h3 {...{
      "id": "equipment"
    }}>{`Equipment`}</h3>
    <p>{`You must have only one weapon and one armor/exoskeleton equipped at any time. Items you have equipped have a green dot on their picture.
If you want to equip another item that you have previously purchased, go on a regen pad, select the item you want to equip and click "Equip".`}</p>
    <p><img alt="Equip" src={require("../public/equip.png")} /></p>
    <h2 {...{
      "id": "cost-reduction"
    }}>{`Cost reduction`}</h2>
    <p>{`Some items reduce the cost of other items when purchased. The reduction is equal to the cost of the purchased item.`}</p>
    <p><img alt="Reductions" src={require("../public/reductions.png")} /></p>
    <p>{`Items with a green cost have their cost reduced thanks to your previous purchases:`}</p>
    <p><img alt="Green Cost" src={require("../public/reduction.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      